html {
	flex-direction: column;
}

body {
	user-select: none;
	font-size: 16px;
	overflow-y: scroll;
	color: black;
}

body a:focus {
	text-decoration: none;
}

body a:hover {
	text-decoration: none;
}

body #webpack-hot-middleware-clientOverlay,
body #webpack-hot-middleware-clientOverlay * {
	user-select: text !important;
}

body #root {
	display: flex;
	transition: opacity .3s ease;
}

body #root ::-moz-selection {
	/* Code for Firefox */

	color: #fff;
	background: rgba(16, 142, 233, 0.6);
}

body #root ::selection {
	color: #fff;
	background: rgba(16, 142, 233, 0.6);
}

* {
	min-height: 0;
}

.ant-table-pagination {
	float: none !important;
	margin: 0 !important;
	padding: 5px;
	font-size: 16px;
	height: 40px;
}

.ant-table-empty {
	padding-top: 30px;
}

.tier-description {
	font-size: 16px;
	color: black;
}

.tier-price {
	font-size: 30px;
	color: black;
}

.delivery-modal-btn {
	font-size: 16px;
	color: black;
}

.delivery-modal .ant-modal-body * {
	font-size: 16px !important;
	color: black !important;
}

.right-sidebar {
	font-size: 16px;
	color: black;
}

.filter-checkbox {
	font-size: 16px;
	color: black;
}

.ScrollUpButton__Container {
	bottom: 50px !important;
	right: 50px !important;;
}

.add-button {
	font-size: 30px;
	height: 50px;
	width: 50px;
}

.remove-button {
	font-size: 30px;
	height: 50px;
	width: 50px;
}

.image-modal .ant-modal {
	width: auto !important;
	min-width: 400px !important;
	max-width: max-content !important;
}

.image-modal .ant-modal-body {
	align-items: center;
	justify-content: center;
	display: flex;
	height: auto !important;
	min-height: 400px !important;
}

.count-input {
	font-size: 22px;
	color: black;
}

.ant-modal-body {
	min-height: 500px;
}
